import React from 'react';
import ReactDOM from 'react-dom'; // For react 17
// For react 18: import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

import { FronteggProvider } from '@frontegg/react';

const contextOptions = {
  // baseUrl: 'https://app-holwszlldohb.frontegg.com/',
  baseUrl: 'https://auth.royhamelekh.com/',
  clientId: '9a2155f9-5bbf-4da0-b69f-2f8f3a5fc760',
  appId: "1beb4041-4846-4c73-b8ef-11034a8400e2",

  tenantResolver: () => {
    const domainParts = window.location.host.split('.');
    // Check if the host is accessed with a subdomain
    if (domainParts.length > 2) {
        const organization = domainParts[0]; // Assuming 'client1' is the tenant identifier in 'client1.royhamelekh.com'
        return { tenant: organization };
    } else {
        // Default tenant or behavior for accessing the root domain
        return { tenant: undefined }; // this is the default login box you see in the builder
    }
},
};

const authOptions = {
 keepSessionAlive: true, // refreshes user token automatically when it reaches 80% expiration time
 enableSessionPerTenant: true
};

ReactDOM.render(
  <FronteggProvider 
    contextOptions={contextOptions}
    hostedLoginBox={false}
    authOptions={authOptions}
  >
    <App />
  </FronteggProvider>,
  document.getElementById('root')
);